import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Breakpoints, BreakpointsDown } from "../../../styles/styleConstants";
import { PRIVACY_URL, TERMS_URL } from "../../../utils/commonConst";
import Image from "../../common/Image";
import ExternalLink from "../../common/externalLink";
import SocialMediaIcons from "../../common/socialMediaIcons";

export default function FooterV2() {
  const currentYear = new Date().getFullYear();
  return (
    <Container className="footerContainer" id="footer">
      <Row>
        <Col>
          <FooterWrapper>
            <Image
              className="footerLogo"
              src="common/uprise-logo.png"
              alt="Uprise"
              style={{
                width: "160px",
                height: "40px",
                display: "inline-block",
              }}
              imgStyle={{ objectFit: "contain" }}
            />
            <FooterLinks>
              <ExternalLink
                href="https://wellfound.com/company/uprisemoney"
                name="career"
              >
                Careers
              </ExternalLink>
              <ExternalLink href={TERMS_URL} name="terms">
                Terms of service
              </ExternalLink>
              <ExternalLink href={PRIVACY_URL} name="privacy">
                Privacy policy
              </ExternalLink>
            </FooterLinks>
            <SocialMediaIconsRow>
              <SocialMediaIcons />
            </SocialMediaIconsRow>
            <LegalDisclaimerWrapper>
              <div>
                <div>
                  Ontrail, Inc. is not a legal, tax, or investment advisor. Our
                  services should not be construed as a recommendation or offer
                  to sell or purchase any particular security or investment
                  product. You acknowledge and agree that your use of or
                  reliance on our services is solely at your own risk and you
                  assume full responsibility for all risk associated. You are
                  solely responsible for your own judgement to assess
                  information provided by Ontrail, Inc. and its staff in
                  connection with our services. Ontrail, Inc. does not guarantee
                  the results of our services and expressly disclaims any
                  guarantee of results or outcomes of any of the recommendations
                  or advice directly or indirectly related to the use of our
                  services and service content.
                </div>
                <div>
                  Ontrail, Inc. dba Uprise © 2022. Ontrail, Inc. is registered
                  in Delaware, United States.
                </div>
              </div>
            </LegalDisclaimerWrapper>
          </FooterWrapper>
        </Col>
      </Row>
    </Container>
  );
}

const FooterWrapper = styled.div`
  text-align: center;
  .footerLogo {
    filter: brightness(0);
  }
  padding-top: 2em;

  @media (max-width: ${Breakpoints.sm}) {
    padding-top: 0;
  }
`;

const LegalDisclaimerWrapper = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: normal;
`;

const FooterLinks = styled.div`
  font-size: 16px;
  font-weight: bold;
  flex-direction: "row";
  align-items: center;
  justify-content: center;
  padding: 2em;

  @media (max-width: ${BreakpointsDown.sm}) {
    font-size: 14px;
    padding: 0;
    > a {
      padding: 10px 6px;
      display: inline-block;
    }
  }
`;

const SocialMediaIconsRow = styled.div`
  padding: 0 0 1.5em;
`;
