import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Breakpoints, Spacing } from "../../../styles/styleConstants";
import { HEADER_LEVEL } from "../../../utils/commonConst";
import HeaderText from "../../common/HeaderText";
import Image from "../../common/Image";

export default function AIHumanSecretV2() {
  return (
    <AIHumanSecretWrapper>
      <Container className="ai-secret-container">
        <Row className="align-items-center container-row">
          <Col md={6} lg={6}>
            <HeaderText
              style={{ margin: `${Spacing.lg} 0` }}
              level={HEADER_LEVEL.LEVEL_6}
            >
              THE SECRET?
            </HeaderText>
            <HeaderText level={HEADER_LEVEL.LEVEL_1}>
              Combining the magic of humans{" "}
              <i>
                <u>and</u>
              </i>{" "}
              AI.
            </HeaderText>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_4_1}
              style={{ marginTop: "24px" }}
            >
              Automated to keep costs down. Human to be authentic and
              personalized.
            </HeaderText>
          </Col>
          <Col md={6} lg={6}>
            <Image
              src="partner/img-humanAI@2x.png"
              className="ai-human-image"
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </Col>
        </Row>
      </Container>
    </AIHumanSecretWrapper>
  );
}

const AIHumanSecretWrapper = styled.div`
  padding: ${Spacing.xl} 0;

  @media (max-width: ${Breakpoints.sm}) {
    padding: 0;
    .ai-secret-container {
      padding: 0px ${Spacing.md} ${Spacing.md} !important;
    }
    .container-row {
      gap: ${Spacing.lg};
    }
  }
`;
