import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import HeaderText from "../../common/HeaderText";
import HelperText from "../../common/HelperText";
import Image from "../../common/Image";
import {
  Breakpoints,
  FontSizes,
  Spacing,
} from "../../../styles/styleConstants";
import { HEADER_LEVEL } from "../../../utils/commonConst";

function WhatYouGetAsPartner(props) {
  const { whatYouGetAsPartnerData } = props ?? {};
  return (
    <WhatYouGetWrapper>
      <Container className="what-you-get-container">
        <WhatYouGetCardWrapper>
          {whatYouGetAsPartnerData.map((element) => {
            return (
              <WhatYouGetCard>
                <ImageWrapper>
                  <Image
                    src={element.logo_path}
                    style={{
                      minWidth: "64px",
                      maxWidth: "64px",
                    }}
                  />
                </ImageWrapper>
                <WhatYouGetDetails>
                  <HeaderText
                    level={HEADER_LEVEL.LEVEL_4}
                    style={{ margin: `${Spacing.md} 0` }}
                  >
                    {element.title}
                  </HeaderText>
                  <HelperText
                    style={{ fontSize: FontSizes.md, lineHeight: "24px" }}
                  >
                    {element.detailText}
                  </HelperText>
                </WhatYouGetDetails>
              </WhatYouGetCard>
            );
          })}
        </WhatYouGetCardWrapper>
      </Container>
    </WhatYouGetWrapper>
  );
}

export default WhatYouGetAsPartner;

const WhatYouGetWrapper = styled.div`
  @media (max-width: ${Breakpoints.sm}) {
    .what-you-get-container {
      padding: 0px ${Spacing.md} ${Spacing.md} !important;
    }
  }
`;

const WhatYouGetCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spacing.xs} 0;
  flex-wrap: wrap;
  margin-top: ${Spacing.lg};
  gap: 20px;
  @media (max-width: ${Breakpoints.lg}) {
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    gap: ${Spacing.lg};
  }
`;

const WhatYouGetCard = styled.div`
  padding: ${Spacing.md} 0;
  background-color: #fff;
  width: 49%;
  border-radius: 10px;
  @media (max-width: ${Breakpoints.sm}) {
    padding: ${Spacing.lg};
  }
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: ${Breakpoints.lg}) {
    margin: auto;
    width: 100%;
  } ;
`;

const WhatYouGetDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${Spacing.md};
  @media (max-width: ${Breakpoints.sm}) {
    padding: 0;
  }
`;

const ImageWrapper = styled.div`
  margin-left: ${Spacing.md};
  @media (max-width: ${Breakpoints.sm}) {
    margin-left: 0;
  }
`;
