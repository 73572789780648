import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Image from "../../common/Image";
import { Breakpoints, Colors, Spacing } from "../../../styles/styleConstants";
import {
  getAppUrlByModule,
  isNonEmpty,
  navigateTo,
} from "../../../utils/commonUtil";

export default function LogoBarV2({
  content,
  reverseColor = false,
  style = {},
  enableWaitlistCta = false,
}) {
  const { title, images, sectionName } = content;
  const imageRows = images.map((imageRow, rowIndex) => {
    const imageRowFormatted = imageRow.map((imageInfo, index) => {
      const { path, width, height, link } = imageInfo ?? {};
      return (
        <RowCell
          reverseColor={reverseColor}
          key={`logobar-${sectionName}-rowCell-${index}`}
          hyperlink={link}
          onClick={(evt) => {
            if (isNonEmpty(link)) {
              window.open(link, "_blank");
            }
            evt.preventDefault();
            evt.stopPropagation();
          }}
        >
          <Image
            className="LogoBarImage"
            key={`logobar-${sectionName}-img-${index}`}
            src={path}
            style={{ width: `${width}`, height: `${height}` }}
            imgStyle={{ objectFit: "contain" }}
          />
        </RowCell>
      );
    });
    return (
      <RowWrapper key={`logobar-${sectionName}-row-${rowIndex}`}>
        {imageRowFormatted}
      </RowWrapper>
    );
  });
  return (
    <LogoBarWrapper reverseColor={reverseColor} style={style}>
      <Container className="logobar-container">
        {isNonEmpty(title) && (
          <RowWrapper reverseColor={reverseColor}>
            <LogoBarTitle>{title}</LogoBarTitle>
          </RowWrapper>
        )}
        {imageRows}
        {enableWaitlistCta === true && (
          <CtaWrapper>
            <CtaButton
              onClick={(evt) => {
                navigateTo(getAppUrlByModule());
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="primary"
            >
              Get started
            </CtaButton>
          </CtaWrapper>
        )}
      </Container>
    </LogoBarWrapper>
  );
}

const LogoBarWrapper = styled.div`
  padding: ${(props) =>
    props?.style?.padding ? props?.style?.padding : `${Spacing.xl} 0px`};
  h4 {
    color: ${(props) => (props.reverseColor ? "white" : Colors.gray500)};
    opacity: ${(props) => (props.reverseColor ? 0.6 : 1)};
  }

  .logobar-container {
    padding: 0px 24px 24px;
    @media (max-width: ${Breakpoints.sm}) {
      padding: 0px ${Spacing.md} ${Spacing.xl};
    }
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  color: ${(props) => (props.reverseColor ? "white" : "inherit")} @media
    (min-width: ${Breakpoints.md}) {
    flex-wrap: nowrap;
  }

  @media (max-width: ${Breakpoints.sm}) {
    justify-content: space-between;
  }
`;

export const RowCell = styled.div`
  margin: ${Spacing.lg} ${Spacing.md};
  filter: ${(props) =>
    props.reverseColor ? "grayscale(100%) brightness(100)" : "grayscale(100%)"};
  opacity: ${(props) => (props?.reverseColor ? 0.6 : 1)};
  cursor: ${(props) => (props?.hyperlink ? "pointer" : "default")};
  :hover {
    opacity: ${(props) => props?.hyperlink && (props?.reverseColor ? 1 : 0.6)};
  }

  @media (max-width: ${Breakpoints.sm}) {
    margin: ${Spacing.lg} 0;
    width: 50%;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LogoBarTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 30px;
  text-align: center;
  color: #9699a3;

  @media (max-width: ${Breakpoints.sm}) {
    text-align: start;
  }
`;

const CtaWrapper = styled.div`
  margin-top: ${Spacing.xxl};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
