import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Breakpoints, Colors, Spacing } from "../../../styles/styleConstants";
import { HEADER_LEVEL } from "../../../utils/commonConst";
import { isNonEmpty, navigateTo } from "../../../utils/commonUtil";
import CtaButton from "../../common/CtaButton";
import HeaderText from "../../common/HeaderText";

export default function PartnerContactUsV2(props) {
  const {
    headerText,
    buttonName,
    buttonLinkTarget,
    buttonLinkHref,
    style,
    className,
    contentStyle,
  } = props;
  return (
    <ContactWrapper style={style} className={className}>
      <Container className="content-wrapper" style={contentStyle}>
        <ContactUsBody>
          <HeaderText level={HEADER_LEVEL.LEVEL_1}>{headerText}</HeaderText>
          <CtaButton
            onClick={(evt) => {
              if (isNonEmpty(buttonLinkTarget)) {
                navigateTo(buttonLinkTarget);
              } else if (isNonEmpty(buttonLinkHref)) {
                window.location = buttonLinkHref;
              }

              evt.preventDefault();
              evt.stopPropagation();
            }}
            ctaType="secondary"
            style={{ minHeight: "48px", width: "100%" }}
          >
            {buttonName}
          </CtaButton>
        </ContactUsBody>
      </Container>
    </ContactWrapper>
  );
}

const ContactWrapper = styled.div`
  padding: ${Spacing.lg} 0;
  margin-bottom: ${Spacing.xxxxl};
  .content-wrapper {
    display: flex;
    background-color: ${Colors.teal};
    justify-content: center;
    align-items: center;
    padding: 64px 0px;
    @media (max-width: ${Breakpoints.sm}) {
      flex-direction: column;
      padding: 64px 16px !important;
      align-items: flex-start;
      gap: 24px;
    }
  }

  @media (max-width: ${Breakpoints.sm}) {
    padding: 64px 0 0;
    margin-bottom: 64px;
  }
`;

const ContactUsBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0% 15%;
  gap: 48px;
  @media (max-width: ${Breakpoints.sm}) {
    text-align: center;
    padding: 0% 0%;
  }
`;
